/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";
import { ImageCopyPage as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query ImageCopyQuery($slug: [String]) {
    craft {
      entry(section: "pageBuilder", slug: $slug) {
        title
        url
        slug
        ... on Craft_pageBuilder_imageCopyPage_Entry {
          typeHandle
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # hero
          heroTitle
          heroLabel
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          heroVideoUrl
          videoAspectRatio
          #featured
          commonPlainHeader
          commonImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          commonCopy
          #blocks
          commonHeader
          commonDescription
          commonFullWidthImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          commonImageCopyBlock {
            ... on Craft_commonImageCopyBlock_imageCopyBlock_BlockType {
              imageCopyBlockTitle
              imageCopyBlockDescription
              imageCopyBlockImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              imageCopyBlockCTA {
                text
                url
              }
              imageCopyBlockPDF {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `craft {
  entry(section: "pageBuilder", slug: $slug) {
    title
    url
    slug
    ... on Craft_pageBuilder_imageCopyPage_Entry {
      typeHandle
      #meta
      metaTitle
      metaDescription
      metaImage {
        width
        height
        hasFocalPoint
        slug
        status
        title
        focalPoint
        url
      }
      # hero
      heroTitle
      heroLabel
      heroImage {
        width
        height
        hasFocalPoint
        slug
        status
        title
        focalPoint
        url
      }
      heroVideoUrl
      videoAspectRatio
      #featured
      commonPlainHeader
      commonImage {
        width
        height
        hasFocalPoint
        slug
        status
        title
        focalPoint
        url
      }
      commonCopy
      #blocks
      commonHeader
      commonDescription
      commonFullWidthImage {
        width
        height
        hasFocalPoint
        slug
        status
        title
        focalPoint
        url
      }
      commonImageCopyBlock {
        ... on Craft_commonImageCopyBlock_imageCopyBlock_BlockType {
          imageCopyBlockTitle
          imageCopyBlockDescription
          imageCopyBlockImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          imageCopyBlockCTA {
            text
            url
          }
          imageCopyBlockPDF {
            url
          }
        }
      }
    }
  }
}
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
    // hero
    heroTitle,
    heroLabel,
    heroImage,
    // intro
    commonPlainHeader,
    commonImage,
    commonCopy,
    // body
    commonHeader,
    commonDescription,
    commonFullWidthImage,
    commonImageCopyBlock,
  } = craft.entry;

  const resolveBlocks = blocks =>
    blocks.map(b => {
      return {
        heading: b.imageCopyBlockTitle,
        copy: b.imageCopyBlockDescription,
        image: resolveImage(b.imageCopyBlockImage),
        link: {
          text: b.imageCopyBlockCTA.text,
          url: b.imageCopyBlockCTA.url,
        },
        pdf: b.imageCopyBlockPDF[0]?.url,
      };
    });

  return {
    hero: {
      label: heroLabel,
      heading: heroTitle,
      image: resolveImage(heroImage),
    },
    intro: {
      heading: commonPlainHeader,
      copy: commonCopy,
      image: resolveImage(commonImage),
    },
    body: {
      heading: commonHeader,
      copy: commonDescription,
      image: resolveImage(commonFullWidthImage),
      blocks: resolveBlocks(commonImageCopyBlock),
    },
    meta: {
      title,
      url,
      slug,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage || heroImage),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
